import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from "../../../../core/auth/service/auth.service";
import {models} from "powerbi-client";

@Component({
  selector: 'app-dashboard-gerencial',
  templateUrl: './dashboard-gerencial.component.html',
  styleUrls: ['./dashboard-gerencial.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardGerencialComponent implements OnInit{

  reportId: "7a6411c5-f79a-4413-8ef3-df93020c14ba"
  groupId: "5e45386e-6583-49fc-9989-a6577193f157"
  embedConfig: any;
  constructor(private readonly authService: AuthService) {}


  ngOnInit(): void {

    this.authService.GetServicePrincipalAccessToken("7a6411c5-f79a-4413-8ef3-df93020c14ba","5e45386e-6583-49fc-9989-a6577193f157").subscribe({
      next: data => {
        if(data){
          this.embedConfig = {
            type: "report",
            id: "7a6411c5-f79a-4413-8ef3-df93020c14ba",
            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=7a6411c5-f79a-4413-8ef3-df93020c14ba&groupId=5e45386e-6583-49fc-9989-a6577193f157",
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              layoutType: models.LayoutType.Master,
              zoomLevel: 0.75,
              customLayout: {
                pageSize: { type: models.PageSizeType.Cortana},
                displayOption: models.DisplayOption.FitToWidth,
                reportAlignment: models.ReportAlignment.Center
              },
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            }
          }
        }
      }
    })

  }

}
