<div class="app-button-options-dots">
    <button
      class="transparent__btn dots__btn"
      pButton 
      (click)="contentTemplate.toggle($event)">
        <img class="mr-5" [src]="getIconPath('icon_vertical_dots')" alt="Options menu"> 
    </button>

    <p-overlayPanel #contentTemplate class="button-options-dots-popover">
      @for (menu of menuConfig; track menu) {
        <button class="botoesMenu dot_menu_option" [class.disable-btn]="menu.disabled"
          (click)="onClickActionMenu(menu.eventType, menu.disabled)"
          [style]="styleCss">
          <div class="span__btn">
            <img *ngIf="menu.icon !== ''" class="mr-5" [src]="getIconPath(menu.icon)" [alt]="menu.title"> 
              {{ menu.title | translate }}
          </div>
        </button>
      }
    </p-overlayPanel>
</div>
