import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FileValidationService {
  // Lista de extensões de arquivo proibidas
  private forbiddenExtensions = [
    'gz', 'z', 'zip', 'rar', '7z', 'tar', 'tar.gz', 'tar.z', 'tar.bz2', 'tar.xz', 'tgz', 'exe'
  ];

  private forbiddenForIA = [
    'doc', 'docx', 'pdf'
  ];

  private forbiddenForGeo = [
    'shp', 'shx', 'dbf', 'prj', 'dwg', 'sig', 'xml', 'kml', 'kmz', 'dxf'
  ];

  public isExtensionForbidden(fileName: string): boolean {
    const extension = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return this.forbiddenExtensions.includes(extension.toLowerCase());
  }

  public isExtensionForIA(fileName: string): boolean {
    const extensionForIA = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return this.forbiddenForIA.includes(extensionForIA.toLowerCase());
  }

  public isExtensionForGeo(fileName: string): boolean {
    const extensionForGeo = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return this.forbiddenForGeo.includes(extensionForGeo.toLowerCase());
  }

  public allowedExtensions (fileName: string, screenForbiddenExtensions : string[]): boolean {
    const extension = fileName.includes('.') ? fileName.slice(fileName.lastIndexOf('.') + 1) : '';
    return screenForbiddenExtensions.includes(extension.toLowerCase());
  }

  isZipFile(file: File): Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {

      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);

        // Verifica se os primeiros bytes correspondem aos bytes mágicos de um arquivo ZIP
        if (uint8Array.length > 2 &&
            uint8Array[0] === 80 && uint8Array[1] === 75 &&
            uint8Array[2] === 3 && uint8Array[6] === 0 && (uint8Array[8] === 8 || uint8Array[8] === 0)) {

          const extension = file.name.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
          if (extension && extension[1].toLowerCase() === 'kmz') {
            resolve(false);
            return;
          }

          resolve(true);
        } else {
          resolve(false);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      // Leitura dos primeiros bytes do arquivo
      reader.readAsArrayBuffer(file.slice(0, 10));
    });
  }

  isExeFile(file: File): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);

        // Verifica se os primeiros bytes correspondem aos bytes mágicos de um arquivo EXE ou MSI do Windows
        if (uint8Array.length > 1 &&
            (uint8Array[0] === 77 && uint8Array[1] === 90) || (uint8Array[0] === 208 && uint8Array[1] === 207)) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      // Leitura dos primeiros 2 bytes do arquivo
      reader.readAsArrayBuffer(file.slice(0, 2));
    });
  }

}
