import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { SummaryAboutProject } from "../../scope-change/interface/summary-scope-change";
import { ProjectsV2Service } from "src/app/shared/services/projects-v2.service";
import { ProjectSuspension } from "src/app/shared/dto/project-suspension.dto";
import moment from "moment";
import { AnalysisInformations } from "src/app/shared/dto/analysis-info.dto";
import { UserService } from "src/app/shared/services/user.service";
import { MessageService } from "src/app/shared/common/services/message.service";
import { LicensingService } from "../../projects/projects-licensing/service/licensing.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EnvLicensingOpinionRequestDto } from "../../../shared/dto/env-licensing-opinion-request.dto";
import { NzModalService } from "ng-zorro-antd/modal";
import { ConfirmationModalComponent } from "../../../shared/components/confirmation-modal/confirmation-modal.component";
import { SuspensionCancellationStatusMeService } from "../../scope-change/service/suspension-cancellation-status-me/suspension-cancellation-status-me.service";
import { UtilService } from "src/app/shared/util.service";
import { buildRoute, RoutesEnum } from "src/app/shared/enum/routes.enum";
import { setPages } from "src/app/shared/ngrx/breadcrumb/breadcrumb.actions";
import { Page } from "src/app/shared/ngrx/breadcrumb/pages.model";
import { Store } from "@ngrx/store";
import { BreadcrumbState } from "src/app/shared/ngrx/breadcrumb/breadcrumb.state.model";

@Component({
  selector: 'app-project-v2-suspension',
  templateUrl: './project-v2-suspension.component.html',
  styleUrls: ['./project-v2-suspension.component.scss'],
})
export class ProjectV2SuspensionComponent implements OnInit {
  projectId: number;
  suspensionId: number;
  projectSummary: SummaryAboutProject;
  suspension: ProjectSuspension;
  label: string;
  analysisInfo: AnalysisInformations;
  licensingProcessesIds: number[] = [];
  licensingProcessList: Array<{ [key: string]: any }> = [];
  studiesList: Array<{ [key: string]: any }> = [];
  formData: FormGroup;
  checkValidateForm: boolean = false;

  studiesListComplete: any[] = []

  suspensionStatus: ProjectSuspension | null = null;
  confirmationUserName?: string;
  finalComments?: string;
  comments: string | undefined;
  confirmationDate: string | undefined;
  confirmationSendDate: string | undefined;
  deadline: string | undefined;
  projectName: string;
  editSuspensionUserResponsible: boolean = false;
  publishFinalAnalysisSuspension: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private projectService: ProjectsV2Service,
    private userService: UserService,
    private messageService: MessageService,
    private licensingService: LicensingService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private readonly suspensionCancellationService: SuspensionCancellationStatusMeService,
    private store: Store<BreadcrumbState>,
  ) {}

  ngOnInit(): void {
    this.projectId = this.route.snapshot?.params['projectId'];
    this.suspensionId = this.route.snapshot?.params['suspensionId'];
    this.getSuspensionInfo();
    this.getLicensingProcessList();
    this.initFormData();
    this.editSuspensionUserResponsible = this.utilService.getAuthorization('edit', 'ANALYSISSUSPENSION');
    this.publishFinalAnalysisSuspension = this.utilService.getAuthorization('insert', 'FINALSUSPENSION');
  }

  getSuspensionInfo() {
    this.projectService.getProjectSuspension(this.suspensionId).subscribe({
      next: (suspension) => {
        this.suspensionStatus = suspension;
        this.projectName = suspension.project.name;
        this.confirmationUserName = suspension.confirmationUser ? suspension.confirmationUser?.name : undefined;
        this.finalComments = suspension.finalComments ?? '';
        this.confirmationDate = suspension.confirmationDate ?? '';
        this.confirmationSendDate = suspension.confirmationSendDate ? suspension.confirmationSendDate : undefined;
        this.deadline = suspension.confirmationDate ?? '';
        this.confirmationSendDate = suspension.confirmationSendDate ? suspension.confirmationSendDate : undefined;
        this.suspension = {
          ...suspension,
          expectedResumeDate: suspension.expectedResumeDate
            ? this.capitalizeFirstLetter(
                moment(suspension.expectedResumeDate, 'YYYY-MM-DD')
                  .locale('pt-br')
                  .format('MMM/YYYY')
              )
            : null,
        };
        this.getProjectSummary();
        this.getAnalysisInfo();

        if (this.suspension.type == 'SUSPENSION') {
          this.label = 'projects.suspension.suspension-label';
        } else {
          this.label = 'projects.suspension.cancel-label';
        }
        this.dispatchProjectOnStore();
      }
    });
  }

  private dispatchProjectOnStore(): void {
    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: this.projectId
    });

    const pagesArray: Page[] = [
      {
        name: "Projetos",
        url: `/${RoutesEnum.PROJECTS}`
      },
      {
        name: this.projectName,
        url: `${routeProject}`
      },
      {
        name: `Requisição de ${this.suspension.type == 'SUSPENSION' ? 'suspensão' : 'cancelamento'}`,
        url: ''
      },
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }

  getAnalysisInfo() {
    this.analysisInfo = {
      number: this.suspension.number,
      responsible: this.suspension.responsibleUser,
      registrationDate: this.suspension.registrationDate,
      deadline: this.suspension.deadline,
      status: this.suspension.status,
    };
  }

  getUserListForAnalysis = () => {
    return this.userService.getUsersByRoleAndRegion('ROLE_GESTOR',
      this.suspension.project?.region.id);
  }

  getProjectSummary() {
    this.projectSummary = {
      projectName: this.suspension.project?.name || "",
      projectCode: this.suspension.project?.code || "",
      projectNumber: this.suspension.project?.number || "",
      projectClassification: this.suspension.project?.classificationType || "",
      projectDescription: this.suspension.project?.comments || "",
      localization: this.getLocalization(this.suspension.project),
      areaRequest: this.suspension.project?.area || "",
      responsible: this.suspension.project?.userLegalRepresentative?.name || ""
    }
  }

  private capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private getLocalization(project: any | null): string {
    let localization = '';

    if (project && project.complex && project.region) {
      localization = `${project.region.description} / ${project.complex.description}`;
      if (project.operationalUnit) {
        localization += ` / ${project.operationalUnit}`;
      }
    }

    return localization;
  }

  getValueChanged = (userId) => {
    this.projectService
      .updateSuspensionResponsible(this.suspensionId, userId)
      .subscribe({
        next: (updatedSuspension) => {
          this.messageService.showSucessMessage('Atualizado com sucesso!');
          this.suspension = updatedSuspension;
          this.getAnalysisInfo();
        },
      });
  };

  getLicensingProcessList() {
    this.licensingService.getLicensingList(this.projectId).subscribe({
      next: (licenses) => {
        const listForLicenses: any[] = [];



        licenses.content.forEach(licenseData => {

          const routeEditprocess = buildRoute(RoutesEnum.EDIT_PROCESS_V2, {
            projectId: this.projectId,
            processId: licenseData.id
          });

          const newLicensingProcessToList = {
            'Título do licenciamento': ['text', licenseData.competentOrgan.code + " - " + licenseData.title],
            'Status': ['status', licenseData.status],
            'Link': routeEditprocess
          }

          this.licensingProcessesIds.push(licenseData.id);
          listForLicenses.push(newLicensingProcessToList);
        });

        this.licensingProcessList = listForLicenses;
        this.getStudiesList();
      },
    });
  }

  getStudiesList() {
    this.licensingService.getEiaStudiesByLicensingProcessList(this.licensingProcessesIds).subscribe({
      next: (studies) => {
        studies.forEach(study => {
          const newStudyToList = {
            'Estudo': ['text', study.title],
            'Status': ['status', study.status],
            'Link': study.route
          }

            this.studiesList.push(newStudyToList);
          });
        },
      });
  }

  goBack() {
    this.location.back();
  }

  redirectToFinalAnalysis() {
    const request = this.buildRequestToSend();
    if(request.actions.length === 0) {
      this.messageService.showErrorMessage('Favor preencher os campos obrigatórios!');
      return;
    }

    this.projectService.sendEnvLicensingOpinion(request).subscribe({
      next: () => {
        this.showConfirmationModal();
        if(!this.suspension.responsibleUser){
          const loggedUser = this.userService.getUserFromSession();
          this.getValueChanged(loggedUser.id);
        }
      },
      error: (err) => {
        let messageToShow = "Erro ao publicar parecer";
        if (err.error && err.error.message) {
          messageToShow = err.error.message;
        } else if (err.error && err.error.errors.details) {
          messageToShow = err.error.errors.details;
        }

        this.messageService.showErrorMessage(messageToShow);
      }
    });
  }

  buildRequestToSend() {
    const request: EnvLicensingOpinionRequestDto = {
      impacts: this.formData.controls['impacts']?.value?.map(elem => elem.description) || [],
      actions: this.formData.controls['actions']?.value?.map(elem => elem.description) || [],
      comments: this.formData.controls['comments']?.value,
      id: this.suspensionId
    };
    return request;
  }

  showConfirmationModal() {
    const modal = this.modal.create<ConfirmationModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzWidth: 555,
      nzCentered: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzContent: ConfirmationModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });
    const route = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: `${this.projectId}`
    });

    const instance = modal.getContentComponent();
    instance.buttonAction = `/${RoutesEnum.PROJECTS}${this.projectId}`;
    instance.title = 'projects.suspension-cancellation.confirmation.title';
    instance.subtitle = 'projects.suspension-cancellation.confirmation.subtitle';
    instance.buttonAction = () => {
      this.router.navigate([route]);
    }

  }

  handleDetailAboutRequest($event: FormGroup) {
    this.checkValidateForm = false;
    this.formData.controls['id'].setValue(this.suspensionId);
    this.formData.controls['actions'].setValue($event.controls['actions'].value);
    this.formData.controls['impacts'].setValue($event.controls['impacts'].value);
    this.formData.controls['comments'].setValue($event.controls['comments'].value);
  }

  initFormData(): void {
    this.formData = this.formBuilder.group({
      id: [null],
      actions: [null, Validators.required],
      impacts: [null],
      comments: [null],
    });
  }
}
