export enum RoutesEnum {
  // PARAMS
  ID = ':id',
  NAME = ':name',
  TYPE = ':type',
  PROJECT_ID = ':projectId',
  SUB_PROJECT_ID = ':subProjectId',
  PROCESS_ID = ':processId',
  SUSPENSION_ID = ':suspensionId',
  RIPLA_TYPE = ':riplaType',
  ASSESSMENT_ID = ':assessmentId',
  REVISION_ID = ':revisionId',
  PRODUCT = ':product',
  SCOPE_ID = ':scopeId',
  ACTIVITY_SUB_ID = ':activitySubId',
  ANALYSIS_SELECTION = ':analysisSelection',
  ANALYSIS_NAME = ':analysisName',
  ANALYSIS_ID = ':analysisId',
  ANALYSIS_Study_ID = ':analysisStudyId',
  ANALYSIS_TYPE= ':analysisType',
  MESSAGE_SUMARY_ID= ':idMessageSummary',
  STUDY_ID= ':studyId',

  // BASE PATHS
  PROJECTS = 'projetos/',
  PROCESSES = 'processos',
  PROCESSE = 'processos',
  REQUISITIONS_RIPLA = 'requisicoes/RIPLA',
  ACTIVITIES = 'atividades',
  SUB_ACTIVITIES = 'nova-subatividade',
  DASHBOARDS = 'dashboards',
  TASKS = 'tasks',
  COMMUNICATION = 'comunicacoes',
  DOCUMENTS = 'documentos',
  HELP_CENTER = 'central-de-ajuda',
  NLP = 'nlp',
  EIA_TR = 'eia-tr',
  SUMMARY = 'sumario-executivo',

  // COMPOSITE PATHS
  PROJECT_BY_ID = `projetos/${PROJECT_ID}`,
  PROJECT_SUBPROJECT_ID = `${PROJECT_ID}/${SUB_PROJECT_ID}`,
  PROCESS_BY_ID = `processos/${PROCESS_ID}`,
  REQUISITIONS_RIPLA_TOKEN = `${REQUISITIONS_RIPLA}/${SUB_PROJECT_ID}`,
  ANALYSIS_SELECTION_NAME = `${ANALYSIS_SELECTION}/${ANALYSIS_NAME}`,

  // DASHBOARDS TYPES
  DASHBOARDS_TYPE_LICENSING = 'licenciamentos',
  DASHBOARDS_TYPE_STUDIES = 'estudos-ambientais',
  DASHBOARDS_TYPE_SUPPLIERS = 'fornecedores',
  DASHBOARDS_TYPE_MANAGEMENT = 'gerencial',

  // SPECIFIC ROUTES
  SCOPE_CHANGE_PARAM = 'mudanca-de-escopo',
  REQUEST = 'pedido-de-revisao',
  NEW_ACTIVITY_PARAM = 'nova-atividade',

  // APP MODULES / SIDEBAR
  PROJECT = 'projetos',
  SCOPE_CHANGE = `${PROJECT_BY_ID}/requisicoes`,
  SUSPENSION = `${SCOPE_CHANGE}/suspensao/${SUSPENSION_ID}`,
  CANCELLATION = `${SCOPE_CHANGE}/cancelamento/${SUSPENSION_ID}`,
  DASHBOARDS_TYPE = `${DASHBOARDS}/:type`,

  // PROJECT
  PROJECT_BY_ID_V2 = `${PROJECT_ID}`,
  EDIT_PROCESS = `${PROJECT_ID}/processos/${PROCESS_ID}`,
  EDIT_PROCESS_V2 = `:projectId/processos/:processId`,
  NEW_PROJECT = `novas-requisicoes/RIPLA/${RIPLA_TYPE}`,
  PROJECT_ANALYSIS = `${PROJECT_ID}/${REQUISITIONS_RIPLA_TOKEN}`,
  PROJECT_CONSOLIDATION_FINAL = `${PROJECT_ANALYSIS}/parecer-final`,
  PROJECT_ASSESSMENT = `${PROJECT_ANALYSIS}/analise-de-disciplina/${ASSESSMENT_ID}`,
  PROJECT_REQUISITION = `${PROJECT_ANALYSIS}/${REQUEST}/${REVISION_ID}`,

  // PROCESS
  NEW_ACTIVITY = `${PROCESS_BY_ID}/nova-atividade`,
  EDIT_ACTIVITY = `${PROCESS_BY_ID}/${ACTIVITIES}`,
  NEW_SUB_ACTIVITY = `${PROCESS_BY_ID}/${SUB_ACTIVITIES}/${ACTIVITY_SUB_ID}/${PRODUCT}`,

  // SCOPE CHANGE
  SCOPE_CHANGE_NEW = 'nova-mudanca-de-escopo',
  SCOPE_CHANGE_DETAIL = `${SCOPE_CHANGE_PARAM}/${ID}`,
  SCOPE_CHANGE_PUBLISH = `${SCOPE_CHANGE_DETAIL}/parecer-final`,
  SCOPE_CHANGE_DETAIL_EDIT = `${SCOPE_CHANGE_PARAM}/${SCOPE_ID}/edit`,
  SCOPE_CHANGE_ASSESSMENT = `${SCOPE_CHANGE_PARAM}/${SCOPE_ID}/analisa-da-disciplina/${ID}`,
  SCOPE_CHANGE_REVIEW = `${SCOPE_CHANGE_PARAM}/${SCOPE_ID}/${REQUEST}`,

  // SMART-DOCS
  SMART_DOCS = 'smart-docs',
  SELECT_EIA = `${PROJECT_ID}/${TYPE}/${NAME}`,
  NEW_ANALYSIS = 'nova-analise',
  NEW_ANALYSIS_ID = `${NEW_ANALYSIS}/${ANALYSIS_ID}`,
  NEW_ANALYSIS_STUDY_ID = `${NEW_ANALYSIS}/estudo/${STUDY_ID}`,


  //SMART-DOCS: STEPS
  STUDY_SELECTION = `${EIA_TR}/selecao/${ANALYSIS_SELECTION_NAME}`,
  STUDY_SELECTION_ANALYSIS_ID = `${STUDY_SELECTION}/${ANALYSIS_ID}`,
  STUDY_SELECTION_STUDY_ID = `${STUDY_SELECTION}/estudo/${STUDY_ID}`,
  TR_SELECTION = `${EIA_TR}/selecao-do-tr/${ANALYSIS_SELECTION_NAME}`,
  VERIFICATION_TR = `${EIA_TR}/verificacao-do-tr/${ANALYSIS_ID}`,
  SELECT_SECTION_TR = `${EIA_TR}/selecao-das-secoes/${ANALYSIS_ID}`,
  RESULT_ANALYSIS = `${EIA_TR}/resultado-da-analise/${ANALYSIS_ID}`,
  ANALYSIS_STUDY_EIA = `estudo-de-impacto-ambiental/${ANALYSIS_Study_ID}`,
  DETAIL_RESULT_EIA = `${ANALYSIS_STUDY_EIA}/analise-do-relatorio/${ANALYSIS_ID}`,

  //SMART-DOCS: SUMMARY
  SUMMARY_DOWNLOAD = `download/${ANALYSIS_ID}`,
  SUMMARY_STUDY = `estudo/${ANALYSIS_TYPE}/${ANALYSIS_NAME}`,
  SUMMARY_RESULT = `resultado/${MESSAGE_SUMARY_ID}/analise/${ANALYSIS_ID}`,
}

export const buildRoute = (route: string, params: { [key: string]: any }): string =>
  Object.keys(params).reduce(
    (builtRoute, param) => {
      const value = params[param];
      return builtRoute.replace(`:${param}`, value !== null && value !== undefined ? value.toString() : '');
    },
    route
  );
