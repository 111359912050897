import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { TasksService } from '../service/tasks.service';
import { TasksCreate } from '../tasks.model';
import { UserService } from 'src/app/shared/services/user.service';
import { ProjectsService } from '../../projects/projects.service';
import { LicensingService } from '../../projects/projects-licensing/service/licensing.service';
import { UtilService } from 'src/app/shared/util.service';

@Component({
  selector: 'app-new-task-modal',
  templateUrl: './new-task-modal.component.html',
  styleUrls: ['./new-task-modal.component.scss']
})
export class NewTaskModalComponent implements OnInit {
readonly #modal = inject(NzModalRef);
form!: UntypedFormGroup;
projectsList;
licensingList;
licensingDisabled: boolean = true;
relatedLicensing: any[] = [];
bodyFormat: any;

statusList=[
  {
    name: "A fazer",
    value: "AFA"
  },
  {
    name: "Em execução",
    value: "EXE"
  },
  {
    name: "Concluído",
    value: "CON"
  }
];

  constructor(private readonly fb: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private taskService: TasksService,
    private projectsService: ProjectsService,
    private licensingService: LicensingService,
    private readonly utilService: UtilService,
    private userService: UserService) { }

  ngOnInit() {
    this.initForm();
    this.statusList.sort((a, b) => a.name.localeCompare(b.name));
  }

  initForm(){
    let taskName = '';
    let taskDeadline = '';
    let relatedProject = '';
    let relatedLicensing = '';
    let taskStatus = '';

    this.form = this.fb.group({
      taskName: [taskName, [Validators.required]],
      taskDeadline: [taskDeadline, [Validators.required]],
      relatedProject: [relatedProject],
      relatedLicensing: [relatedLicensing],
      taskStatus: [taskStatus],
    });

    this.getProjectList();

  }

  onChange(result: Date): void {
    this.form.value.taskDeadline = result;
  }

  disabledDate = (current: Date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current < currentDate;
  };

  submitForm() {
    const payload:TasksCreate = this.buildPayload();

    this.taskService.createTask(payload).subscribe({
      next: () => {
        this.message.showSucessMessage('Tarefa criada com sucesso!');
        this.close();
      },
      error: () => {
        this.message.showErrorMessage('Erro ao criar Tarefa');
      }
    });
  }

  buildPayload(): TasksCreate{
    let taskDeadlineDateFormatted = this.utilService.DateFormatter(
      this.form.value?.taskDeadline
    );
    const loggedUser = sessionStorage.getItem("loggedUser");
    const user = loggedUser ?  JSON.parse(loggedUser) : null;
    let taskNameSanitized = this.utilService.sanitizeInput(this.form.value.taskName);
    this.bodyFormat = {
      "description": taskNameSanitized,
      "deadline": taskDeadlineDateFormatted,
      "status": this.form.value?.taskStatus,
      "priority": false,
      "projectId": this.form.value?.relatedProject,
      "projectLicensingId": this.form.value?.relatedLicensing,
      //"productDemandId": ,
      "userId": user.id // TODO loggedUser
    };

    return this.bodyFormat
  }

  showCancelConfirm(): void {
    if (this.form.dirty) {
      this.modal.confirm({
        ...(this.message.showMessageModalConfirmCancel() as any),
        nzOnOk: () => {

          this.close();
        },
      });
    } else {
      this.close();
    }
  }

  close(){
    this.#modal.close();
  }

  private getProjectList() {
    this.projectsService.getProjectsList(0,200).subscribe((projectsList) => {
      this.projectsList = projectsList.content;
      this.projectsList = this.projectsList;
    });
  }

  selectProject(event: any) {
    if(event && this.form.value?.relatedProject){
      this.licensingDisabled = false
      this.getLicensingByProject(event)
    } else {
      this.form.get('relatedLicensing')?.reset();
      this.licensingList = [];
      this.licensingDisabled = true
    }
  }

  private getLicensingByProject(id) {
    this.licensingService.getLicensingList(id).subscribe((result) => {
      this.licensingList = result.content;
    });
  }


}
